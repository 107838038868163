"use strict";
// WARNING! This file should be imported before any other imports in the module.
var href = window.location.href;
var publicPath = process.env.ASSETS_PATH;
if (href.startsWith('https://cdn.websky.aero/demo')) {
    publicPath = 'https://cdn.websky.aero/demo/static/';
}
else if (href.startsWith('https://cdn.websky.aero/content/DP/html')) {
    publicPath = 'https://cdn.websky.aero/test/static/';
}
__webpack_public_path__ = publicPath;
