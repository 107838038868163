import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { LinearProgress, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import LoyaltyConnect, { ConnectType } from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { useTheme } from '../../../theme';
import { useLoyaltyInfo } from '../../hooks';
import { useIsMobile } from '../../../utils';
import PriceInMiles from '../../../PriceInMiles/PriceInMiles';
import Menu from '../Menu/Menu';
import Button from '../../../Button';
import Modal from '../../../Modal';
import SureModal from '../../../SureModal';
import MilesRecovery from '../../../MilesRecovery/components/MilesRecovery';
import { OverrideComponent } from '../../../renderProps';
var FfpInfo = function (_a) {
    var className = _a.className, userInfo = _a.userInfo, ffpInfo = _a.ffpInfo, links = _a.links, _b = _a.showLinksCount, showLinksCount = _b === void 0 ? 4 : _b, onLoyaltySaved = _a.onLoyaltySaved, onLogoutFfp = _a.onLogoutFfp, onGoToSettings = _a.onGoToSettings, isLoading = _a.isLoading;
    var css = useTheme('LoyaltyInfo').FfpInfo;
    var t = useTranslation('Loyalty').t;
    var isMobile = useIsMobile();
    var _c = __read(useState(null), 2), connectType = _c[0], setConnectType = _c[1];
    var onCloseConnect = function () {
        setConnectType(null);
    };
    var _d = useLoyaltyInfo(ffpInfo), nextLevel = _d.nextLevel, milesPercentage = _d.milesPercentage, faresPercentage = _d.faresPercentage;
    var setConnectTypeHandler = function (type) { return function () { return setConnectType(type); }; };
    var _e = __read([links.slice(0, showLinksCount), links.slice(showLinksCount)], 2), mainLinks = _e[0], otherLinks = _e[1];
    var renderLinks = function () { return (React.createElement("div", { className: css.links }, mainLinks
        .filter(function (_a) {
        var url = _a.url;
        return !!url;
    })
        .map(function (link, index) { return (React.createElement("a", { href: link === null || link === void 0 ? void 0 : link.url, target: "_blank", className: css.links_item, key: index }, t(link.title))); }))); };
    React.useEffect(function () {
        if (isLoading) {
            onCloseConnect();
        }
    }, [isLoading]);
    var onLoyaltyCardSaved = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onLoyaltySaved()];
                case 1:
                    _a.sent();
                    onCloseConnect();
                    return [2 /*return*/];
            }
        });
    }); };
    var onLogoutFfpHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onLogoutFfp()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    if (!ffpInfo) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.container, className) },
                React.createElement("div", { className: css.text }, t('Start saving now')),
                renderLinks(),
                !isLoading && (React.createElement("div", { className: css.footer },
                    React.createElement(Button, { className: cn(css.button, css.button_gray), onClick: setConnectTypeHandler(ConnectType.Login), isLoading: isLoading }, t('I have a card')),
                    React.createElement(Button, { className: css.button, onClick: setConnectTypeHandler(ConnectType.Create) }, t('Registration')))),
                isLoading && (React.createElement("div", { className: css.loader },
                    React.createElement(Button, { className: css.loader__indicator, isLoading: true }),
                    t('Please wait, we are updating information about your loyalty card. It make take a  few minutes...')))),
            React.createElement(Modal, { maxWidth: 'sm', open: !!connectType, slideUp: isMobile, onClose: onCloseConnect },
                React.createElement(LoyaltyConnect, { userInfo: userInfo, type: connectType, onLoyaltyCardSaved: onLoyaltyCardSaved, onClose: onCloseConnect, onGoToSettings: onGoToSettings }))));
    }
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.header },
            React.createElement(PriceInMiles, { moneyClassName: css.miles, currencyClassName: css.miles_currency, amount: ffpInfo.numberOfMiles }),
            React.createElement(Menu, { className: css.menu }, function (onClickMenu) { return __spreadArray(__spreadArray([], __read(otherLinks.map(function (link, index) {
                var openUrl = function () { return window.open(link.url); };
                return (React.createElement(MenuItem, { key: index, onClick: onClickMenu(openUrl) }, link.title));
            })), false), [
                React.createElement(MilesRecovery, { renderTrigger: function (onClick) { return React.createElement(MenuItem, { onClick: onClick }, t('Miles recovery')); } }),
                React.createElement(SureModal, { title: t('You sure?'), description: t('Delete card from your account?'), onSuccess: onLogoutFfpHandler, renderTrigger: function (onClick) { return (React.createElement(MenuItem, { onClick: onClick }, t('Exit and add another card'))); } })
            ], false); })),
        nextLevel && (React.createElement(OverrideComponent, { componentProps: null, component: {
                FfpInfoStatuses: function () {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css.progress_status },
                            React.createElement(LinearProgress, { classes: {
                                    root: css.progress,
                                    colorPrimary: css.progress_bg,
                                    barColorPrimary: css.progress_color
                                }, variant: 'determinate', value: milesPercentage }),
                            React.createElement("span", null, t('Left {{miles}} for {{level}} status', {
                                miles: ffpInfo.milesForNextLevel.toLocaleString(),
                                level: t(nextLevel)
                            }))),
                        React.createElement("div", { className: css.progress_fares },
                            React.createElement(LinearProgress, { classes: {
                                    root: css.progress,
                                    colorPrimary: css.progress_bg,
                                    barColorPrimary: css.progress_color
                                }, variant: 'determinate', value: faresPercentage }),
                            React.createElement("span", null,
                                t('or'),
                                ' ',
                                (ffpInfo === null || ffpInfo === void 0 ? void 0 : ffpInfo.segmentsForNextLevel) > 1 ? ffpInfo.segmentsForNextLevel : 1,
                                ' ',
                                t('out of'),
                                " ",
                                ffpInfo.currentLevelMaxSegments,
                                " ",
                                t('flights')))));
                }
            } })),
        !nextLevel && !ffpInfo.milesForNextLevel && (React.createElement(OverrideComponent, { componentProps: { ffpInfo: ffpInfo }, component: {
                FfpInfoStatusesMaxLevel: function () { return null; }
            } })),
        renderLinks()));
};
export default FfpInfo;
