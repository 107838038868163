import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useState } from 'react';
import { useTheme } from '../../../../theme';
import SocialAuth from '../../../../SocialAuth';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { emailRegex, MOBILE_MIN_WIDTH } from '../../../../utils';
import SubmitButton from '../SubmitButton/SubmitButton';
import LoginInput from '../LoginInput/LoginInput';
import { useConfig } from '../../../../context';
import StayLoggedIn from './StayLoggedIn/StayLoggedIn';
import { useMediaQuery } from 'react-responsive';
import { LoginType, useAccountExistenceQuery, useSendSecureCodeMutation } from '@websky/graphql';
import { clearPhoneNumber, isCorrectPhoneNumber } from '../../../../PhoneInput/utils';
import { useToggleable } from '../../../../hooks';
import { getCorporateNumber } from '../../../../Loyalty/utils';
import Switch from '../../../../PassengerForm/components/components/Switch/Switch';
var LoginForm = function (_a) {
    var onSuccess = _a.onSuccess, initialData = _a.initialData, stayLoggedIn = _a.stayLoggedIn, setStayLoggedIn = _a.setStayLoggedIn, onError = _a.onError;
    var css = useTheme('LoginPage').LoginForm;
    var t = useTranslation('LoginPage').t;
    var _b = useConfig().global, socialAuthMethods = _b.socialAuthMethods, iataCode = _b.companyInfo.iataCode;
    var isB2 = iataCode === 'B2';
    var FFPRegex = useConfig().global.FFPRegex;
    var availableAuthMethods = socialAuthMethods.filter(function (method) { return method.isAvailable; });
    var _c = __read(useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var _d = __read(useState(false), 2), isShownCorporateCardSwitch = _d[0], setShownCorporateCardSwitch = _d[1];
    var _e = __read(useSendSecureCodeMutation(), 1), sendCode = _e[0];
    var userExistence = useAccountExistenceQuery({
        skip: true
    }).refetch;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _f = useToggleable(false), isActiveCorporateCard = _f.isOpen, onToggleCorporateCard = _f.toggle, onHideCorporateCard = _f.close;
    var validate = useCallback(function (values) {
        var errors = {};
        if (!values.login) {
            errors['login'] = t('Required');
        }
        else if (!emailRegex.test(values.login) &&
            !isCorrectPhoneNumber(values.login) &&
            !FFPRegex.test(values.login)) {
            errors['login'] = t('Please enter a valid Email / Phone / Card number');
        }
        if (isB2) {
            if (values.login && FFPRegex.test(values.login)) {
                setShownCorporateCardSwitch(true);
            }
            else {
                setShownCorporateCardSwitch(false);
                onHideCorporateCard();
            }
        }
        return errors;
    }, []);
    var onSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var pureLogin, login, loginType, isAccountExistence, data;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setLoading(true);
                    pureLogin = values.login;
                    login = pureLogin;
                    if (emailRegex.test(login)) {
                        loginType = LoginType.Email;
                    }
                    else if (isCorrectPhoneNumber(login)) {
                        loginType = LoginType.Phone;
                        login = clearPhoneNumber(pureLogin);
                    }
                    else if (FFPRegex.test(login)) {
                        loginType = LoginType.FFP;
                        if (isActiveCorporateCard) {
                            login = getCorporateNumber(pureLogin);
                        }
                    }
                    return [4 /*yield*/, userExistence({
                            params: {
                                login: login,
                                loginType: loginType
                            }
                        })];
                case 1:
                    isAccountExistence = _c.sent();
                    if (!((_b = (_a = isAccountExistence === null || isAccountExistence === void 0 ? void 0 : isAccountExistence.data) === null || _a === void 0 ? void 0 : _a.AccountExistence) === null || _b === void 0 ? void 0 : _b.result)) return [3 /*break*/, 3];
                    return [4 /*yield*/, sendCode({
                            variables: {
                                params: {
                                    login: login,
                                    loginType: loginType
                                }
                            }
                        })];
                case 2:
                    data = _c.sent();
                    if (data.data.SendSecureCode.result) {
                        onSuccess({
                            login: login,
                            type: loginType,
                            needRegistration: false
                        });
                    }
                    else {
                        onError('Something went wrong');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    onSuccess({
                        login: login,
                        type: loginType,
                        needRegistration: true
                    });
                    _c.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onSuccess, isActiveCorporateCard]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title }, t('Log in')),
        React.createElement("div", { className: css.text }, t("If you don't have an account, it will be created automatically")),
        React.createElement(Form, { initialValues: {
                login: initialData === null || initialData === void 0 ? void 0 : initialData.login
            }, validate: validate, onSubmit: !isLoading ? onSubmit : function () { return null; }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.row },
                        React.createElement("div", { className: css.input_wrapper },
                            React.createElement(Field, { name: 'login', render: function (props) { return React.createElement(LoginInput, __assign({}, props)); } })),
                        isMobile && React.createElement(StayLoggedIn, { isActive: stayLoggedIn, setActive: setStayLoggedIn }),
                        isMobile && isShownCorporateCardSwitch && (React.createElement("div", { className: css.loyaltySwitch_wrapper },
                            React.createElement(Switch, { switchPosition: "right", className: css.loyaltySwitch_root, labelClassName: css.loyaltySwitch_label, label: t('Passenger:Corporate card'), isActive: isActiveCorporateCard, onClick: onToggleCorporateCard }))),
                        React.createElement("div", { className: css.button_wrapper },
                            React.createElement(SubmitButton, { loading: isLoading }, t('Continue'))))));
            } }),
        !isMobile && React.createElement(StayLoggedIn, { isActive: stayLoggedIn, setActive: setStayLoggedIn }),
        !isMobile && isShownCorporateCardSwitch && (React.createElement("div", { className: css.loyaltySwitch_wrapper },
            React.createElement(Switch, { switchPosition: "right", className: css.loyaltySwitch_root, labelClassName: css.loyaltySwitch_label, label: t('Passenger:Corporate card'), isActive: isActiveCorporateCard, onClick: onToggleCorporateCard }))),
        availableAuthMethods.length > 0 && (React.createElement("div", { className: css.social_wrapper },
            React.createElement("div", { className: css.social_title }, t('or with you social network')),
            React.createElement(SocialAuth, null)))));
};
export default LoginForm;
