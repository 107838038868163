import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { getUniqueValidOptions } from '../../Results/utils';
import { Money } from '../../Money';
import Tooltip from '../../Tooltip';
import Button from '../../Button';
import { icons as defaultOptionsIcons, infoIcon, payment as paymentIcons } from '../icons';
import CompareFares from '../../CompareFares';
import { initI18n, MOBILE_MIN_WIDTH, useIsDesktop } from '../../utils';
import { useTheme } from '../../theme';
import RecommendedBadge from './RecommendedBadge/RecommendedBadge';
import Dialog from '../../Service/components/Dialog/Dialog';
import Modal from '../../Modal';
import * as Icons from '../../Icons';
import Link from '../../Link';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import Notification from '../../Notification';
import FareGroupIcon from './FareGroupIcon/FareGroupIcon';
import { useCallback } from 'react';
import { useRenderers } from '../../renderProps';
import { useConfig } from '../../context';
import { FareFeaturePaymentType, FlightClass, InfoMessageTypeEnum } from '@websky/graphql';
initI18n('FareGroup');
var FareGroup = function (props) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h;
    var fare = props.fare, onSelect = props.onSelect, price = props.price, originalPrice = props.originalPrice, _j = props.variant, variant = _j === void 0 ? 'blue' : _j, _k = props.isDisabled, isDisabled = _k === void 0 ? false : _k, _l = props.excludeRepeatableKeyOptions, excludeRepeatableKeyOptions = _l === void 0 ? true : _l, _m = props.showUnavailableOptions, showUnavailableOptions = _m === void 0 ? false : _m, upgradeOption = props.upgradeOption, onUpgrade = props.onUpgrade, flightId = props.flightId, className = props.className, isSelected = props.isSelected, titleClassName = props.titleClassName, optionsClassName = props.optionsClassName, optionClassName = props.optionClassName, keyFeatureClassName = props.keyFeatureClassName;
    var css = useTheme('FareGroup').FareGroup;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var isY7 = iataCode === 'Y7';
    var isDesktop = useIsDesktop();
    var options = getUniqueValidOptions(fare.options, false, false, showUnavailableOptions);
    var renderFareGroupOptionIcon = useRenderers().renderFareGroupOptionIcon;
    var _o = __read(React.useState(false), 2), isModalOpen = _o[0], openModal = _o[1];
    var t = useTranslation('FareGroup').t;
    var icons = (_b = props.iconsForKeyFeatures) !== null && _b !== void 0 ? _b : defaultOptionsIcons;
    var payment = (_c = props.iconsForPaymentTypes) !== null && _c !== void 0 ? _c : paymentIcons;
    var handleOnSelect = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (!isDisabled && onSelect) {
            onSelect();
        }
    };
    var renderFareGroupOptionValue = useRenderers().renderFareGroupOptionValue;
    var renderOptionValue = useCallback(function (availability) {
        if (renderFareGroupOptionValue) {
            return renderFareGroupOptionValue(availability);
        }
        return payment[availability];
    }, [payment, renderFareGroupOptionValue]);
    var showOriginalPrice = originalPrice && originalPrice.amount !== price.amount;
    var hideSelectText = originalPrice && originalPrice.amount !== price.amount && originalPrice.amount.toString().length > 5;
    var renderOptionItem = function (option, index) {
        var _a;
        var _b = __read(React.useState(false), 2), isTooltipOpened = _b[0], setTooltipOpened = _b[1];
        var openTooltip = function (state) {
            var _a;
            setTooltipOpened(state);
            if (props.setTooltipsOpened) {
                (_a = props.setTooltipsOpened) === null || _a === void 0 ? void 0 : _a.call(props, state);
            }
        };
        return (React.createElement("li", { key: index, "data-option-type": option.type, className: cn(css.option, optionClassName, (_a = {},
                _a[css.option_unavailable] = option.availability === FareFeaturePaymentType.NotAvailable,
                _a[css.option_free] = option.availability === FareFeaturePaymentType.Free,
                _a)) },
            React.createElement(FareGroupIcon, { optionType: option.type }),
            React.createElement("span", { className: css.option__title }, option.title),
            React.createElement(Tooltip, { title: option.description ? option.description : option.title, tooltipClassName: css.option__tooltip, enterTouchDelay: 0, open: props.isTooltipsOpened ? props.isTooltipsOpened && isTooltipOpened : isTooltipOpened, onOpen: function () { return openTooltip(true); }, onClose: function () { return openTooltip(false); } },
                React.createElement("span", { className: css.option__value }, option.value && !isY7 ? option.value : renderOptionValue(option.availability)))));
    };
    var renderHeader = function (props) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.title, titleClassName) },
                fare.title,
                !fare.description && (React.createElement("span", { onClick: function () { return openModal(true); }, className: css.info }, infoIcon)),
                fare.description && (React.createElement(Tooltip, { title: fare.description, enterTouchDelay: 0 },
                    React.createElement("span", { onClick: function () { return openModal(true); }, className: css.info }, infoIcon)))),
            React.createElement("div", { className: css.keyFeatures }, options.map(function (option, index) {
                var _a;
                return option.isKeyOption &&
                    icons.hasOwnProperty(option.type) && (React.createElement(Tooltip, { key: index, title: option.description ? "".concat(option.title, " - ").concat(option.description) : option.title, enterTouchDelay: 0 },
                    React.createElement("div", { "data-option-type": option.type, className: cn(css.keyFeature, keyFeatureClassName, (_a = {},
                            _a[css.keyFeature_unavailable] = option.availability === FareFeaturePaymentType.NotAvailable,
                            _a[css.keyFeature_paid] = !props.disableClassForHeaderPayFeature &&
                                option.availability === FareFeaturePaymentType.Pay,
                            _a)) },
                        React.createElement("span", { className: css.keyFeature__icon }, renderFareGroupOptionIcon
                            ? renderFareGroupOptionIcon(option.type)
                            : icons[option.type]),
                        React.createElement("span", { className: css.keyFeature__value }, option.value ? option.value : payment[option.availability]))));
            }))));
    };
    var renderOptions = function (props) {
        return (React.createElement("ul", { className: cn(css.options, optionsClassName) }, options.map(function (option, index) {
            return excludeRepeatableKeyOptions
                ? !option.isKeyOption && renderOptionItem(option, index)
                : renderOptionItem(option, index);
        })));
    };
    var renderUpgradeOption = function (props) {
        return upgradeOption && (React.createElement("div", { className: css.upgradeOffer, onClick: onUpgrade },
            upgradeOption.upgradeOffer,
            React.createElement(Link, { action: function () { return null; }, className: css.upgradeOffer__link },
                t('Choose'),
                " ",
                upgradeOption.canUpgradeTo.fareFamily.title,
                Icons.Chevron)));
    };
    var renderButton = function (props) {
        var _a;
        return (React.createElement(Button, { className: css.button, disabled: isDisabled, variant: "flat", size: "large", onClick: isDesktop ? handleOnSelect : function () { return null; }, onTouchEndCapture: !isDesktop ? handleOnSelect : null, "data-tariff": fare.title },
            t(isDisabled && !props.disabledForMiles ? 'Unavailable' : hideSelectText ? null : 'Select'),
            !isDisabled && !props.priceInMiles && showOriginalPrice && (React.createElement(Money, { moneyClassName: cn(css.originalPrice, (_a = {},
                    _a[css.originalPrice_left] = hideSelectText,
                    _a)), money: originalPrice, showCurrency: false })),
            !isDisabled && !props.priceInMiles && React.createElement(Money, { moneyClassName: css.price, money: price }),
            props.priceInMiles > 0 && (React.createElement(PriceInMiles, { moneyClassName: cn(css.price, css.price_miles), currencyClassName: css.currency, amount: props.priceInMiles })),
            isDisabled && Icons.NotIncluded));
    };
    return (React.createElement("div", { "data-fareId": fare.id, className: cn(className, css.fareGroup, (_a = {},
            _a[css.fareGroup_unavailable] = isDisabled,
            _a[css.fareGroup_Economy] = fare.category === FlightClass.Economy,
            _a[css.fareGroup_Business] = fare.category === FlightClass.Business,
            _a[css.fareGroup_white] = variant === 'white',
            _a[css.fareGroup_recommended] = fare.isRecommended,
            _a)) },
        fare.isRecommended && !isDisabled && !isSelected && React.createElement(RecommendedBadge, null),
        React.createElement("div", null,
            ((_d = props.renderProps) === null || _d === void 0 ? void 0 : _d.renderHeader) ? props.renderProps.renderHeader(props) : renderHeader(props),
            ((_e = props.renderProps) === null || _e === void 0 ? void 0 : _e.renderOptions) ? props.renderProps.renderOptions(props) : renderOptions(props)),
        ((_f = props.renderProps) === null || _f === void 0 ? void 0 : _f.renderButton) ? props.renderProps.renderButton(props) : renderButton(props),
        React.createElement("div", { className: css.upgradeOptionWrapper }, ((_g = props.renderProps) === null || _g === void 0 ? void 0 : _g.renderUpgradeOption)
            ? props.renderProps.renderUpgradeOption(props)
            : renderUpgradeOption(props)),
        ((_h = props.renderProps) === null || _h === void 0 ? void 0 : _h.renderRulesButton) && props.renderProps.renderRulesButton(props),
        props.priceInMiles && !props.disabledForMiles && (React.createElement("div", { className: css.taxes },
            t('Taxes'),
            ":",
            React.createElement(Money, { money: props.price }))),
        props.disabledForMiles && (React.createElement(Notification, { className: css.miles_notification, type: 'context', status: InfoMessageTypeEnum.Info, infoMessage: {
                text: t('Not enough miles on your card'),
                type: InfoMessageTypeEnum.Info,
                code: ''
            } })),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { isOpen: isModalOpen, dialogType: "fullscreen", dialogHeaderClassName: css.dialog__header, dialogContentClassName: css.dialog__content, dialogHeader: t('Compare fare families'), onClose: function () { return openModal(false); }, content: React.createElement(CompareFares, { code: fare.airline.iata, flightId: flightId }), mountOnEnter: true })),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { open: isModalOpen, onClose: function () { return openModal(false); } },
                React.createElement(CompareFares, { code: fare.airline.iata, flightId: flightId })))));
};
export default FareGroup;
