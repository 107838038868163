import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import * as icons from './icons';
import { useTheme } from '../../../theme';
import { useMobileSegmentFlatInfo } from './hooks';
import AircraftPopup from '../../../AircraftPopup';
import Point from '../../../DesktopFlightRoute/components/Segment/Point/Point';
import RouteArrow from '../../../DesktopFlightRoute/components/Segment/RouteArrow/RouteArrow';
import { getDurationString } from '../../../Results/utils';
import Amenities from '../../../Amenities';
import StopsInfo from '../../../DesktopFlightRoute/components/Segment/StopsInfo/StopsInfo';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context';
import ImageWithFallback from '../../../ImageWithFallback';
export var MobileSegmentType;
(function (MobileSegmentType) {
    MobileSegmentType[MobileSegmentType["Recommended"] = 0] = "Recommended";
    MobileSegmentType[MobileSegmentType["Selected"] = 1] = "Selected";
})(MobileSegmentType || (MobileSegmentType = {}));
var MobileSegmentFlat = function (props, _a) {
    var _b;
    var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var type = _a.type;
    var theme = useTheme('MobileSegmentInfo').MobileSegmentFlat;
    var t = useTranslation('DesktopFlightInfo').t;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var hasOperatingCarrier = ['A9', 'RM'].includes(iataCode);
    var _o = useMobileSegmentFlatInfo(props), departureDate = _o.departureDate, arrivalDate = _o.arrivalDate, firstSegment = _o.firstSegment, lastSegment = _o.lastSegment, canceled = _o.canceled;
    var operatingCarrier = hasOperatingCarrier && (firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.marketingAirline.iata) !== (firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline.iata)
        ? firstSegment.operatingAirline.name
        : null;
    var companyName = (_c = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline) === null || _c === void 0 ? void 0 : _c.name;
    var companyIcon = (_d = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline) === null || _d === void 0 ? void 0 : _d.icon;
    var renderAircraftButton = useCallback(function (onClick) {
        var _a, _b;
        return (React.createElement("div", { className: cn(theme.image) },
            React.createElement(ImageWithFallback, { src: (_a = firstSegment.aircraft) === null || _a === void 0 ? void 0 : _a.icon, fallbackSrc: 'https://cdn.websky.aero/content/default-content/images/Aircrafts/738/738.png', alt: (_b = firstSegment.aircraft) === null || _b === void 0 ? void 0 : _b.name, onClick: onClick })));
    }, [firstSegment]);
    return (React.createElement(Card, { elevation: 0, className: cn(theme.card, props.className) }, (_f = (_e = props.renderCompanyName) === null || _e === void 0 ? void 0 : _e.call(props, { companyName: companyName, segment: props.segments[0] })) !== null && _f !== void 0 ? _f : (React.createElement("div", { className: theme.title },
        React.createElement("img", { src: companyIcon, alt: companyName }),
        companyName)),
        React.createElement("div", { className: cn(theme.segment, (_b = {},
                _b[theme.canceled] = canceled && !props.multipleSegments,
                _b)) },
            React.createElement(Point, { time: firstSegment.departure.time, airport: firstSegment.departure.airport, mobile: true, date: departureDate }),
            React.createElement(RouteArrow, { timeInRoute: getDurationString(firstSegment.duration), mobile: true, flightNumber: "".concat(firstSegment.marketingAirline.iata, "-").concat(firstSegment.flightNumber), stopCount: props.stopCount }),
            React.createElement(Point, { time: lastSegment.arrival.time, airport: lastSegment.arrival.airport, mobile: true, arrival: true, date: arrivalDate })),
        React.createElement("div", { className: theme.icons },
            type === MobileSegmentType.Recommended && icons.recommendedIcon,
            type === MobileSegmentType.Selected && icons.selectedIcon),
        props.showAirplaneInfo && canceled && React.createElement("div", { className: theme.canceled__warning }, t('Canceled')),
        props.showAirplaneInfo && !canceled && (React.createElement("div", { className: theme.aircraft },
            React.createElement(AircraftPopup, { id: (_g = firstSegment.aircraft) === null || _g === void 0 ? void 0 : _g.id, operatingIata: firstSegment.operatingAirline.iata, name: (_h = firstSegment.aircraft) === null || _h === void 0 ? void 0 : _h.name, renderButton: renderAircraftButton }),
            React.createElement("div", { className: theme.aircraft__details },
                React.createElement("div", { className: theme.aircraft__name }, (_j = firstSegment.aircraft) === null || _j === void 0 ? void 0 : _j.name),
                ((_l = (_k = firstSegment.flightInfo) === null || _k === void 0 ? void 0 : _k.amenities) === null || _l === void 0 ? void 0 : _l.length) > 0 && (React.createElement(Amenities, { amenities: firstSegment.flightInfo.amenities }))),
            operatingCarrier && (React.createElement("div", { className: theme.aircraft__operatingCarrier },
                t('Operating carrier'),
                ": ",
                operatingCarrier)),
            (props.segments[0].transferDuration || ((_m = firstSegment.stops) === null || _m === void 0 ? void 0 : _m.length) > 0) && (React.createElement(StopsInfo, { stopCityName: firstSegment.arrival.airport.city.name, stops: firstSegment.stops, transferDuration: props.segments[0].transferDuration, className: theme.stop })),
            props.totalDuration && React.createElement("div", { className: theme.totalDuration }, props.totalDuration)))));
};
export default MobileSegmentFlat;
