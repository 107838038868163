import { LoginInputType } from './types';
import { emailRegex } from '../utils';
import { isCorrectPhoneNumber } from '../PhoneInput/utils';
export var detectInputType = function (value) {
    if (emailRegex.test(value)) {
        return LoginInputType.Email;
    }
    else if (isCorrectPhoneNumber(value)) {
        return LoginInputType.Phone;
    }
    else {
        return LoginInputType.Text;
    }
};
