import { __awaiter, __generator } from "tslib";
import { useInsurancePageWrapper } from '../../../../InsurancePage/hooks';
import { StepsEnum } from '../../../../types';
export var useCheckoutInsuranceRoute = function (_a) {
    var _b;
    var goToNextStep = _a.goToNextStep, steps = _a.steps;
    var insurancePageWrapper = useInsurancePageWrapper({ strategy: 'checkout-insurance-step' });
    var handleNextStep = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, insurancePageWrapper.saveOrderServicesCallback(goToNextStep)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAgree = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!insurancePageWrapper.priorityInsurance) return [3 /*break*/, 2];
                    return [4 /*yield*/, insurancePageWrapper.handleAgree()];
                case 1:
                    _a.sent();
                    goToNextStep();
                    return [2 /*return*/];
                case 2:
                    insurancePageWrapper.handleAgree();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDisagree = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, insurancePageWrapper.handleDisagree()];
                case 1:
                    _a.sent();
                    goToNextStep();
                    return [2 /*return*/];
            }
        });
    }); };
    var isCanGoBack = ((_b = steps.find(function (step) { return step.type === StepsEnum.Passengers; })) === null || _b === void 0 ? void 0 : _b.disabled) === false;
    return {
        // methods
        handleNextStep: handleNextStep,
        handleAgree: handleAgree,
        handleDisagree: handleDisagree,
        setInsurancePriceToPay: insurancePageWrapper.setInsurancePriceToPay,
        setSaveOrderServicesParams: insurancePageWrapper.setSaveOrderServicesParams,
        setIsNeedToSendSaveReq: insurancePageWrapper.setIsNeedToSendSaveReq,
        // data
        insurancePriceToPay: insurancePageWrapper.insurancePriceToPay,
        orderPriceToPay: insurancePageWrapper.orderPriceToPay,
        priorityInsurance: insurancePageWrapper.priorityInsurance,
        isUpsaleOpen: insurancePageWrapper.isUpsaleOpen,
        isLoading: insurancePageWrapper.isLoading,
        isCanGoBack: isCanGoBack
    };
};
