import { __assign } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import MealPage from '../../../../MealPage';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { getPassengerLabels } from '../../../store/passengers/selectors';
import { getBonusForFlight, getIsConfirmed, getMeals, getOrder, getOrderId, getPriceInMiles, getRecommendedMeals, getSegmentsForServices, getServices, getShowMealRecommendations, getTravellers, hasBaggageServices, hasSeatsServices } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import { MEAL_RECOMMENDATION_SHOWN, set } from '../../../../cache';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getMealsPriceToPay, getTotalPriceToPay } from '../../../store/orderPrice/selectors';
import { addServiceAction, removeServiceAction, deleteAllTypesOnSegment, setServiceAction, saveServicesAction, setMultipleSelectedServices as setMultipleSelectedServicesAction } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible, getSelectedServicesState } from '../../../store/selectedServices/selectors';
import { getMealReduxCallbacks } from '../../../utils';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { useMediaQuery } from 'react-responsive';
import { isServicesSaving } from '../../../store/servicesIsSaving/selectors';
import AvailableServicesCountContextProvider from '../../../../AdditionalServices/context';
var Meal = function (_a) {
    var totalPrice = _a.totalPrice, passengersState = _a.passengersState, setNextStep = _a.goToNextStep, goToPrevStep = _a.goToPrevStep, hasBaggageStep = _a.hasBaggageStep, hasSeatsStep = _a.hasSeatsStep, isConfirmed = _a.isConfirmed, passengerLabels = _a.passengerLabels, mealTotalSum = _a.mealTotalSum, setService = _a.setService, services = _a.services;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').CheckoutStyles;
    var dispatch = useDispatch();
    var segments = useSelector(getSegmentsForServices);
    var meals = useSelector(getMeals);
    var recommendedMeals = useSelector(getRecommendedMeals);
    var showRecommendations = useSelector(getShowMealRecommendations);
    var orderId = useSelector(getOrderId);
    var travellers = useSelector(getTravellers);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMiles = useSelector(getPriceInMiles);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    var order = useSelector(getOrder);
    var selectedServices = useSelector(getSelectedServicesState);
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    React.useEffect(function () {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    }, []);
    var selectedMeals = [];
    passengersState.forEach(function (passenger) {
        if (passenger.services) {
            passenger.services.forEach(function (product) {
                var serviceObject = services.find(function (_a) {
                    var id = _a.id;
                    return id === product.serviceId;
                });
                if (serviceObject && serviceObject.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    selectedMeals.push({
                        passengerId: passenger.id,
                        segmentId: product.segmentIds[0],
                        mealId: product.serviceId,
                        count: product.count,
                        confirmedCount: product.confirmedCount
                    });
                }
            });
        }
    });
    // Do not show recommendations twice.
    var cacheRecommendationState = function () { return set("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId), true); };
    var mealCallbacks = useMemo(function () { return getMealReduxCallbacks(dispatch); }, [dispatch]);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice: totalPrice,
            onButtonClick: setNextStep,
            onBackButtonClick: hasSeatsStep || hasBaggageStep ? goToPrevStep : null,
            additional: mealTotalSum.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Meal'),
                ": ",
                React.createElement(Money, { money: mealTotalSum }))) : null,
            miles: bonusForFlight,
            priceInMiles: priceInMiles
        } },
        React.createElement(AvailableServicesCountContextProvider, { value: { order: order, selectedServices: selectedServices } },
            React.createElement(MealPage, __assign({ segments: segments, passengers: passengersState.map(function (_a) {
                    var id = _a.id;
                    return ({
                        id: id,
                        name: passengerLabels[id]
                    });
                }), readonly: isConfirmed, showRecommendations: showRecommendations, meals: meals, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals, onMobileProceed: setNextStep, onMobileBack: goToPrevStep, onRecommendationConfirm: function () {
                    travellers.map(function (_a) {
                        var preselectedServices = _a.preselectedServices, id = _a.id;
                        preselectedServices.gdsServices.services
                            .filter(function (service) { return meals.some(function (meal) { return meal.id === service.serviceId; }); })
                            .forEach(function (service) {
                            setService({
                                passengerId: id,
                                count: service.count,
                                segmentId: service.segmentIds[0],
                                allowedSegments: [service.segmentIds],
                                serviceId: service.serviceId,
                                service: {
                                    id: service.serviceId,
                                    type: OrderAdditionalServiceGdsServiceServiceType.Meal
                                }
                            });
                        });
                        cacheRecommendationState();
                    });
                }, onRecommendationDisagree: cacheRecommendationState, isLoading: isOrderServicesSaving }, mealCallbacks)))));
};
var mapStateToProps = function () {
    return function (state, ownProps) { return (__assign(__assign({}, ownProps), { isConfirmed: getIsConfirmed(state), passengersState: getPassengerStateCompatible(state), totalPrice: getTotalPriceToPay(state), hasSeatsStep: hasSeatsServices(state), hasBaggageStep: hasBaggageServices(state), passengerLabels: getPassengerLabels(state), mealTotalSum: getMealsPriceToPay(state), services: getServices(state) })); };
};
var mapDispatchToProps = {
    fillOrder: fillOrder,
    addService: addServiceAction,
    removeService: removeServiceAction,
    setService: setServiceAction,
    saveService: saveServicesAction,
    setMultipleSelectedServices: setMultipleSelectedServicesAction,
    deleteAllTypesOnSegment: deleteAllTypesOnSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Meal);
