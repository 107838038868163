import { __read, __spreadArray } from "tslib";
import { animalBaggageTypes, getBaggageDatalayerName, getServiceCategory, getTravellersTypes } from './utils';
import { OrderAdditionalServiceGdsServiceServiceType, OrderStatus, CheckinOrderStatus } from '@websky/graphql';
import { DataLayerItemsListName, ItemCategory, WxEvents } from './types';
import getOrderDataLayer from './orderDataLayerInstance';
import WebskyServicesAnalytics from './WebskyServicesAnalytics';
import { isFareLockService } from '../FareLock/utils';
import Gds from './Diff/Gds';
import Aeroexpress from './Diff/Aeroexpress';
import Seat from './Diff/Seat';
import { getHoldDatalayerItem } from './Diff/utils';
import { convertBaggageObject } from '../Checkout/types';
import { InsuranceGoal, reachGoal } from '../analytics';
import { Transliteration } from '../transliteration';
import { CheckinOrderFragmentDoc, CheckoutOrderFragmentDoc } from '@websky/graphql';
var WebskyAnalyticsApollo = /** @class */ (function () {
    function WebskyAnalyticsApollo() {
    }
    WebskyAnalyticsApollo.isCheckoutOrderGuard = function (order) {
        return order === null || order === void 0 ? void 0 : order.hasOwnProperty('flight');
    };
    WebskyAnalyticsApollo.analyzeOrderQuery = function (orderAfterChange) {
        var orderBeforeChange = this.classStoredCachedOrder;
        if (this.isQueryAfterOrderWasPaid(orderAfterChange) || this.isQueryAfterServicesWerePaid(orderAfterChange)) {
            getOrderDataLayer().purchaseOrder(orderAfterChange.locator, WebskyServicesAnalytics.getCheckoutSelectedServices(orderBeforeChange) // orderBeforeChange because we need services and order.priceToPay.airticket?.amount
            );
        }
        if (this.isQueryAfterOrderWasPaid(orderAfterChange)) {
            var selectedInsurances = WebskyServicesAnalytics.getCheckoutSelectedServices(orderBeforeChange).filter(function (service) { return service.category === ItemCategory.Insurance; });
            if (selectedInsurances.length) {
                var insurancesToAnalytics = selectedInsurances
                    .map(function (item) { return Transliteration.cyrillicToLatin(item.name).replace(/ /g, '_'); })
                    .join('_');
                reachGoal((InsuranceGoal.PurchaseWithInsurance + insurancesToAnalytics));
            }
        }
    };
    WebskyAnalyticsApollo.analyzeGetCheckinOrderQuery = function (orderAfterChange) {
        var orderBeforeChange = this.classStoredCachedOrder;
        if ((orderBeforeChange === null || orderBeforeChange === void 0 ? void 0 : orderBeforeChange.status) === CheckinOrderStatus.Started &&
            orderAfterChange.status === CheckinOrderStatus.Confirmed) {
            getOrderDataLayer().purchaseOrder(orderBeforeChange.id, WebskyServicesAnalytics.getCheckinSelectedServices(orderBeforeChange) // orderBeforeChange for correct calculation additional services
            );
        }
    };
    WebskyAnalyticsApollo.analyzeSaveOrderServicesQuery = function (orderAfterChange) {
        var orderBeforeChange = this.classStoredCachedOrder;
        if (!orderBeforeChange) {
            return;
        }
        var added = [];
        var removed = [];
        var differs = [Gds, Seat, Aeroexpress];
        differs.forEach(function (Differ) {
            var differ = new Differ(orderBeforeChange, orderAfterChange);
            var diff = differ.diff();
            added.push.apply(added, __spreadArray([], __read(diff.added), false));
            removed.push.apply(removed, __spreadArray([], __read(diff.removed), false));
        });
        getOrderDataLayer().removeServicesFromCart(removed);
        getOrderDataLayer().addServicesToCart(added);
    };
    WebskyAnalyticsApollo.analyzePriceFreezeView = function () {
        var _b, _c, _d, _e, _f;
        var freezeService = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(isFareLockService);
        if (freezeService) {
            getOrderDataLayer().setViewItem(getHoldDatalayerItem(freezeService, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    };
    WebskyAnalyticsApollo.analyzeViewItem = function (e, type) {
        var _b, _c, _d, _e, _f, _g, _h;
        var options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.servicesIds) || !(options === null || options === void 0 ? void 0 : options.segmentId)) {
            return null;
        }
        var item = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(function (service) {
            var _b;
            return options.servicesIds.includes(service.id) &&
                ((_b = service.allowedSegments) === null || _b === void 0 ? void 0 : _b.some(function (segmentsIds) {
                    return segmentsIds.some(function (segmentId) { return segmentId === options.segmentId; });
                }));
        });
        if (item) {
            getOrderDataLayer().setViewItem({
                category: getServiceCategory(type),
                name: item.name,
                price: item.price.amount,
                segmentId: options.segmentId,
                isRecommend: (_g = (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers) === null || _g === void 0 ? void 0 : _g.some(function (traveller) {
                    var _b, _c;
                    return (_c = (_b = traveller.preselectedServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) {
                        return service.serviceId === item.id;
                    });
                }),
                count: 1,
                travellersTypes: getTravellersTypes((_h = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _h === void 0 ? void 0 : _h.travellers)
            });
        }
    };
    WebskyAnalyticsApollo.analyzeAeroexpressView = function () {
        var _b, _c, _d, _e, _f;
        var availableServices = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.transfer) === null || _d === void 0 ? void 0 : _d.aeroexpress) === null || _e === void 0 ? void 0 : _e.trips;
        if (availableServices) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Aeroexpress, WebskyServicesAnalytics.aeroexpress(availableServices, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    };
    WebskyAnalyticsApollo.analyzePetsView = function () {
        var _b, _c, _d, _e, _f;
        var pets = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(function (service) {
            return animalBaggageTypes.includes(service.baggageType) &&
                service.canBeAdded;
        });
        if (pets) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Pets, WebskyServicesAnalytics.baggage(pets.map(function (service) {
                return convertBaggageObject(service);
            }), (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    };
    WebskyAnalyticsApollo.analyzeInsuranceView = function (e) {
        var _b, _c, _d, _e, _f;
        var options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.servicesIds)) {
            return null;
        }
        var insurances = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.insurances) === null || _d === void 0 ? void 0 : _d.insurancePrograms) === null || _e === void 0 ? void 0 : _e.filter(function (insurance) { return options.servicesIds.includes(insurance.code); });
        if (insurances) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Insurance, WebskyServicesAnalytics.insurances(insurances, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    };
    WebskyAnalyticsApollo.analyzeBaggageListView = function () {
        var _b, _c, _d, _e;
        var baggages = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(function (service) {
            return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
                !animalBaggageTypes.includes(service.baggageType) &&
                service.canBeAdded;
        });
        var dataLayerBaggages = [];
        baggages === null || baggages === void 0 ? void 0 : baggages.forEach(function (baggage) {
            var _b;
            (_b = baggage.allowedSegments) === null || _b === void 0 ? void 0 : _b.forEach(function (segmentIds) {
                segmentIds === null || segmentIds === void 0 ? void 0 : segmentIds.forEach(function (segmentId) {
                    var _b, _c, _d;
                    dataLayerBaggages.push({
                        name: getBaggageDatalayerName(baggage),
                        price: baggage.price.amount,
                        category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Baggage, baggage.baggageType),
                        segmentId: segmentId,
                        count: 1,
                        isRecommend: (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.travellers) === null || _c === void 0 ? void 0 : _c.some(function (traveller) {
                            var _b, _c, _d;
                            return (_d = (_c = (_b = traveller.preselectedServices) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.some(function (service) {
                                return service.serviceId === baggage.id;
                            });
                        }),
                        travellersTypes: getTravellersTypes((_d = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _d === void 0 ? void 0 : _d.travellers)
                    });
                });
            });
        });
        getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Baggage, dataLayerBaggages);
    };
    WebskyAnalyticsApollo.analyzeMealsListView = function (e) {
        var _b, _c, _d, _e;
        var options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.segmentId)) {
            return;
        }
        var meals = (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(function (service) {
            return service.type === OrderAdditionalServiceGdsServiceServiceType.Meal &&
                service.allowedSegments.some(function (segmentsIds) {
                    return segmentsIds.some(function (segmentId) { return segmentId === options.segmentId; });
                }) &&
                service.canBeAdded;
        });
        var dataLayerMeals = [];
        meals === null || meals === void 0 ? void 0 : meals.forEach(function (meal) {
            var _b, _c, _d;
            dataLayerMeals.push({
                category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Meal),
                name: meal.name,
                price: meal.price.amount,
                segmentId: options.segmentId,
                isRecommend: (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.travellers) === null || _c === void 0 ? void 0 : _c.some(function (traveller) {
                    var _b, _c;
                    return (_c = (_b = traveller.preselectedServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) {
                        return service.serviceId === meal.id;
                    });
                }),
                count: 1,
                travellersTypes: getTravellersTypes((_d = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _d === void 0 ? void 0 : _d.travellers)
            });
        });
        getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Meal, dataLayerMeals);
    };
    WebskyAnalyticsApollo.analyzeSeatsListView = function (e) {
        var _b, _c, _d, _e, _f, _g, _h, _j;
        var options = e.detail.options;
        if (!(options === null || options === void 0 ? void 0 : options.segmentId)) {
            return;
        }
        var seats = WebskyAnalyticsApollo.isCheckoutOrderGuard(WebskyAnalyticsApollo.classStoredCachedOrder)
            ? (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.flight) === null || _c === void 0 ? void 0 : _c.seatMap) === null || _d === void 0 ? void 0 : _d.segments) === null || _e === void 0 ? void 0 : _e[options.segmentId]
            : (_h = (_g = (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.seatMap) === null || _g === void 0 ? void 0 : _g.segments) === null || _h === void 0 ? void 0 : _h[options.segmentId];
        var travellers = (_j = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _j === void 0 ? void 0 : _j.travellers;
        if (seats && travellers) {
            getOrderDataLayer().setServicesViewItemList(DataLayerItemsListName.Seats, WebskyServicesAnalytics.seats(seats, travellers));
        }
    };
    WebskyAnalyticsApollo.analyzeVipListView = function (e) {
        var _b, _c, _d, _e, _f;
        var options = e.detail.options;
        var vipServices = [];
        if (!(options === null || options === void 0 ? void 0 : options.subType)) {
            return null;
        }
        (_e = (_d = (_c = (_b = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _b === void 0 ? void 0 : _b.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.forEach(function (service) {
            var typedService = service;
            if (typedService.canBeAdded &&
                [
                    OrderAdditionalServiceGdsServiceServiceType.Vip,
                    OrderAdditionalServiceGdsServiceServiceType.BusinessLounge
                ].includes(typedService.type) &&
                typedService.subType === options.subType &&
                typedService.canBeAdded) {
                vipServices.push(typedService);
            }
        });
        if (vipServices.length) {
            getOrderDataLayer().setServicesViewItemList(WebskyServicesAnalytics.getVipItemListName(vipServices[0]), WebskyServicesAnalytics.vip(vipServices, (_f = WebskyAnalyticsApollo.classStoredCachedOrder) === null || _f === void 0 ? void 0 : _f.travellers));
        }
    };
    WebskyAnalyticsApollo.analyzeWxViewTypeWrapper = function (e) {
        WebskyAnalyticsApollo.analyzeWxView(e);
    };
    WebskyAnalyticsApollo.analyzeWxView = function (e) {
        switch (e.detail.itemCategory) {
            case WxEvents.PriceFreeze:
                WebskyAnalyticsApollo.analyzePriceFreezeView();
                break;
            case WxEvents.MealItem:
                WebskyAnalyticsApollo.analyzeViewItem(e, OrderAdditionalServiceGdsServiceServiceType.Meal);
                break;
            case WxEvents.AeroexpressList:
                WebskyAnalyticsApollo.analyzeAeroexpressView();
                break;
            case WxEvents.PetsList:
                WebskyAnalyticsApollo.analyzePetsView();
                break;
            case WxEvents.InsurancesList:
                WebskyAnalyticsApollo.analyzeInsuranceView(e);
                break;
            case WxEvents.BaggageList:
                WebskyAnalyticsApollo.analyzeBaggageListView();
                break;
            case WxEvents.BaggageItem:
                WebskyAnalyticsApollo.analyzeViewItem(e, OrderAdditionalServiceGdsServiceServiceType.Baggage);
                break;
            case WxEvents.MealsList:
                WebskyAnalyticsApollo.analyzeMealsListView(e);
                break;
            case WxEvents.SeatsList:
                WebskyAnalyticsApollo.analyzeSeatsListView(e);
                break;
            case WxEvents.VipList:
                WebskyAnalyticsApollo.analyzeVipListView(e);
                break;
            default:
                break;
        }
    };
    WebskyAnalyticsApollo.setClassStoredOrder = function (operation, response, client) {
        var _b;
        // key - operationName, value - path to order in response
        var initializationQueriesOperationNameAndPath = {
            CreateOrder: 'CreateOrder',
            FindOrder: 'FindOrder',
            Order: 'Order',
            GetCheckinOrder: 'CheckinOrder',
            CheckinCreateOrder: 'CheckinCreateOrder',
            CheckinStart: 'CheckinStart'
        };
        var currentInitializationQuery = Object.entries(initializationQueriesOperationNameAndPath).find(function (_b) {
            var _c = __read(_b, 2), name = _c[0], path = _c[1];
            return operation.operationName === name && response.data[path];
        });
        var orderId = (_b = response.data[operation.operationName === 'GetCheckinOrder' ? 'CheckinOrder' : operation.operationName]) === null || _b === void 0 ? void 0 : _b.id;
        var cachedCheckoutOrder = WebskyAnalyticsApollo.readCachedOrder(client, orderId, 'checkout');
        var cachedCheckinOrder = WebskyAnalyticsApollo.readCachedOrder(client, orderId, 'checkin');
        if (currentInitializationQuery && !(cachedCheckoutOrder || cachedCheckinOrder)) {
            var _c = __read(currentInitializationQuery, 2), pathToOrder = _c[1];
            this.classStoredCachedOrder = response.data[pathToOrder];
        }
        else {
            if (cachedCheckoutOrder || cachedCheckinOrder) {
                this.classStoredCachedOrder = cachedCheckoutOrder !== null && cachedCheckoutOrder !== void 0 ? cachedCheckoutOrder : cachedCheckinOrder;
            }
        }
    };
    WebskyAnalyticsApollo.listenWxViewEvent = function () {
        document.removeEventListener(this.wxView, this.analyzeWxViewTypeWrapper);
        document.addEventListener(this.wxView, this.analyzeWxViewTypeWrapper);
    };
    WebskyAnalyticsApollo.dispatchWxViewEvent = function (itemCategory, options) {
        var event = new CustomEvent(this.wxView, { detail: { itemCategory: itemCategory, options: options } });
        document.dispatchEvent(event);
    };
    WebskyAnalyticsApollo.analyze = function (operation, response, client) {
        if (!(response === null || response === void 0 ? void 0 : response.data)) {
            return;
        }
        WebskyAnalyticsApollo.setClassStoredOrder(operation, response, client);
        switch (operation.operationName) {
            case 'Order':
                this.analyzeOrderQuery(response.data.Order);
                break;
            case 'SaveOrderServices':
                this.analyzeSaveOrderServicesQuery(response.data.SaveOrderServices);
                break;
            case 'CheckinSaveOrderServices':
                this.analyzeSaveOrderServicesQuery(response.data.CheckinSaveOrderServices);
                break;
            case 'GetCheckinOrder':
                this.analyzeGetCheckinOrderQuery(response.data.CheckinOrder);
                break;
        }
    };
    var _a;
    _a = WebskyAnalyticsApollo;
    WebskyAnalyticsApollo.wxView = 'wxView';
    WebskyAnalyticsApollo.classStoredCachedOrder = null;
    WebskyAnalyticsApollo.readCachedOrder = function (client, orderId, type) {
        var params = {
            fragment: type === 'checkout' ? CheckoutOrderFragmentDoc : CheckinOrderFragmentDoc,
            fragmentName: type === 'checkout' ? 'CheckoutOrder' : 'CheckinOrder',
            id: type === 'checkout' ? "AviaOrder:".concat(orderId) : "CheckinOrder:".concat(orderId)
        };
        try {
            var order = client.readFragment(params);
            return order;
        }
        catch (e) {
            return null;
        }
    };
    WebskyAnalyticsApollo.isQueryAfterOrderWasPaid = function (orderAfterChange) {
        var orderBeforeChange = _a.classStoredCachedOrder;
        return (orderBeforeChange === null || orderBeforeChange === void 0 ? void 0 : orderBeforeChange.status) === OrderStatus.Booked && orderAfterChange.status === OrderStatus.Confirmed;
    };
    WebskyAnalyticsApollo.isQueryAfterServicesWerePaid = function (orderAfterChange) {
        var orderBeforeChange = _a.classStoredCachedOrder;
        var unpaidServicesBeforeChange = WebskyServicesAnalytics.getCheckoutSelectedServices(orderBeforeChange);
        var unpaidServicesAfterChange = WebskyServicesAnalytics.getCheckoutSelectedServices(orderAfterChange);
        return ((orderBeforeChange === null || orderBeforeChange === void 0 ? void 0 : orderBeforeChange.status) === OrderStatus.Confirmed &&
            orderAfterChange.status === OrderStatus.Confirmed &&
            unpaidServicesBeforeChange.length &&
            !unpaidServicesAfterChange.length);
    };
    return WebskyAnalyticsApollo;
}());
export default WebskyAnalyticsApollo;
