if (!process.env.DOCKER_BUILD) {
    require('../../change-public-path');
}
import * as React from 'react';
import { render } from 'react-dom';
import { enableSentry } from '../../../utils';
import Engine from './Engine';
import { withConfig } from '../../../config-hoc';
import { CardType } from '../../../PromoLoader/types';
import merge from 'lodash.merge';
enableSentry();
var promoLoaders = [
    {
        type: CardType.Aircraft,
        title: 'It is safe to fly',
        text: 'From locking middle seats to completely disinfecting our aircraft, we do our best to keep you safe.',
        image: 'https://cdn.websky.aero/content/frontend/images/demo-LoadingBanners/safety_fly.png'
    },
    {
        type: CardType.Aircraft,
        title: '2,000 miles giveaway!',
        text: 'To thank you for your loyalty and support, we grant our club members 2,000 miles!',
        image: 'https://cdn.websky.aero/content/frontend/images/demo-LoadingBanners/loyalty.png'
    },
    {
        type: CardType.Seats,
        title: 'Choose your seats when booking',
        text: 'Don’t let chance affect your comfort and convenience.',
        image: 'https://cdn.websky.aero/content/frontend/images/demo-LoadingBanners/seats.png'
    },
    {
        type: CardType.Seats,
        title: 'Save up to 25% when buying extra baggage right away',
        text: '',
        image: 'https://cdn.websky.aero/content/frontend/images/demo-LoadingBanners/baggage.png'
    }
];
export var init = function (mount, config) {
    var Component = withConfig(Engine, merge({}, {
        Engine: {
            promoLoaders: promoLoaders
        }
    }, config));
    render(React.createElement(Component, null), mount);
};
