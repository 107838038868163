import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import Dialog from '../../../../../../Service/components/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import SeatMap from '../../../../../../SeatMap/components/Component';
import BaggagePage from '../../../../../../BaggagePage/components/BaggagePage';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalBaggage, getBaggage, getBrandIncludedBaggage, getIsOddOrderId, getMeals, getOverweightBaggage, getSeatMap, getSegmentsForServices, getSpecialEquipment, getUpgradeBaggage } from '../../../../../store/order/selectors';
import { setSplitTest, SplitTest } from '../../../../../../analytics';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../../../theme';
import { MealWrapper } from '../../../../../../MealPage/components/MealWrapper';
import MealService from '../../../../../../MealService';
import { ServiceType } from '../../types';
import { getBaggageReduxCallbacks, getMealReduxCallbacks, getSeatReduxCallbacks } from '../../../../../utils';
import { getSelectedBaggage, getSelectedExtraSeats } from '../../../../../store/passengers/selectors';
import { ShowLoaderAfterBookingContext } from '../../../ServicePage/ServicePageCtx';
import SimpleLoader from '../../../../../../SimpleLoader';
import { isServicesSaving } from '../../../../../store/servicesIsSaving/selectors';
import { useConfig } from '../../../../../../context';
import Toolbar from '../../../../../../Toolbar';
import { OrderStatus } from '@websky/graphql';
import { getSelectedServicesState } from '../../../../../store/selectedServices/selectors';
import AvailableServicesCountContextProvider from '../../../../../../AdditionalServices/context';
import { useSeatMapToolbarProps } from '../../../../../../SeatMap/components/hooks';
var ServiceCardDialog = function (_a) {
    var _b;
    var onClose = _a.onClose, openedModal = _a.openedModal, mealTotalPrice = _a.mealTotalPrice, selectedMeals = _a.selectedMeals, order = _a.order, passengers = _a.passengers, passengerLabels = _a.passengerLabels, addService = _a.addService, removeService = _a.removeService, setService = _a.setService, seatsTotalPrice = _a.seatsTotalPrice, baggageTotalPrice = _a.baggageTotalPrice, setMultipleSelectedServices = _a.setMultipleSelectedServices;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').OrderPaidServiceCards;
    var seatMapCss = useTheme('SeatMap').ComponentStyles;
    var segments = useSelector(getSegmentsForServices);
    var dispatch = useDispatch();
    var isOddOrderId = useSelector(getIsOddOrderId);
    var baggage = useSelector(getBaggage);
    var meals = useSelector(getMeals);
    var includedBaggage = useSelector(getBrandIncludedBaggage);
    var upgradeBaggage = useSelector(getUpgradeBaggage);
    var additionalBaggage = useSelector(getAdditionalBaggage);
    var specialBaggage = useSelector(getSpecialEquipment);
    var overweightBaggage = useSelector(getOverweightBaggage);
    var selectedBaggage = useSelector(getSelectedBaggage);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    var selectedExtraSeats = useSelector(getSelectedExtraSeats);
    var showLoaderafterBooking = useContext(ShowLoaderAfterBookingContext);
    var selectedServices = useSelector(getSelectedServicesState);
    var seatMap = useSelector(getSeatMap);
    var _c = useSeatMapToolbarProps(seatMap), segmentIndex = _c.segmentIndex, passengerIndex = _c.passengerIndex, setSegment = _c.setSegment, setPassenger = _c.setPassenger, onToolbarButtonClick = _c.onToolbarButtonClick;
    var dialogRef = useRef();
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _d = useConfig().global, maxBaggage = _d.maxBaggage, maxBaggagePerPassenger = _d.maxBaggagePerPassenger, dynamicBaggagePricing = _d.dynamicBaggagePricing;
    var simpleBaggageSelector = isOddOrderId && (segments.length === 1 || isMobile);
    if (typeof maxBaggage === 'number' || typeof maxBaggagePerPassenger === 'number') {
        simpleBaggageSelector = false;
    }
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    var fareFamiliesMap = new Map();
    order.flight.segmentGroups.forEach(function (segmentGroup) {
        var fareFamily = segmentGroup.fareFamily;
        if (!fareFamily) {
            return;
        }
        if (!fareFamiliesMap.has(fareFamily.id)) {
            fareFamiliesMap.set(fareFamily.id, {
                fareFamily: fareFamily,
                segmentIds: []
            });
        }
        segmentGroup.segments.forEach(function (segment) { return fareFamiliesMap.get(fareFamily.id).segmentIds.push(segment.id); });
    });
    var baggageCallbacks = useMemo(function () { return getBaggageReduxCallbacks(passengers, baggage, segments, addService, removeService, setService); }, [baggage, passengers, segments]);
    var setAdditionalBaggage = useCallback(function (services) {
        setMultipleSelectedServices(services);
    }, []);
    var mealCallbacks = useMemo(function () { return getMealReduxCallbacks(dispatch); }, [dispatch]);
    var seatMapCallbacks = useMemo(function () { return getSeatReduxCallbacks(passengers, setMultipleSelectedServices); }, [
        passengers,
        setMultipleSelectedServices
    ]);
    var baggageStyles = useMemo(function () { return ({ BaggagePage: { BaggagePage: { baggage: css.baggagePage__baggage } } }); }, []);
    var hideHeader = useMemo(function () {
        return !isMobile && (openedModal === ServiceType.Seats || openedModal === ServiceType.Meal);
    }, [isMobile, openedModal]);
    var disable = order.status === OrderStatus.Cancelled || !order.canAddServices;
    var initialSelectedMeals = React.useMemo(function () {
        return __spreadArray([], __read(selectedMeals), false);
    }, [isOrderServicesSaving]);
    return (React.createElement(Dialog, { dialogType: 'fullscreen', isOpen: !!openedModal, dialogHeader: t(openedModal), totalPrice: openedModal === ServiceType.Meal && !isMobile && mealTotalPrice, withoutControls: true, dialogContentClassName: cn((_b = {},
            _b[css.baggage__dialogContent] = openedModal === ServiceType.Baggage,
            _b)), hideHeader: hideHeader, onClose: onClose, ref: dialogRef, content: React.createElement(React.Fragment, null,
            showLoaderafterBooking && React.createElement(SimpleLoader, null),
            openedModal === ServiceType.Seats && (React.createElement(React.Fragment, null,
                React.createElement(SeatMap, __assign({ passengers: passengers, showRecommendations: false, seatMap: order.flight.seatMap, fullScreen: false, segmentIndex: segmentIndex, passengerIndex: passengerIndex, onProceed: onClose, isLoading: isOrderServicesSaving, onClose: onClose, setSegment: setSegment, setPassenger: setPassenger, selectedExtraSeats: selectedExtraSeats, isNewOrder: order.status === OrderStatus.New, dialogRef: dialogRef, className: seatMapCss.dialogWrapper }, seatMapCallbacks)),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Toolbar, { totalPrice: seatsTotalPrice, onButtonClick: function () { return onToolbarButtonClick(onClose); }, onBackButtonClick: onClose })))),
            openedModal === ServiceType.Baggage && (React.createElement(ThemeProvider, { value: baggageStyles },
                React.createElement(BaggagePage, __assign({ simpleBaggageSelector: simpleBaggageSelector, baggage: __spreadArray([], __read(baggage), false), segments: segments, passengers: passengers, passengerLabels: passengerLabels, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, isLoading: isOrderServicesSaving, hideBanner: isMobile, dynamicBaggagePricing: dynamicBaggagePricing, disabled: disable }, baggageCallbacks, { setAdditionalBaggage: setAdditionalBaggage })),
                React.createElement(Toolbar, { totalPrice: baggageTotalPrice, onButtonClick: onClose, onBackButtonClick: onClose }))),
            openedModal === ServiceType.Meal && (React.createElement("div", { className: css.meal__dialogContent },
                React.createElement(MealService, { meals: meals, segments: segments, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, isLoading: isOrderServicesSaving, onMobileBack: onClose, onMobileProceed: onClose, onClose: onClose, mealRenderer: function (meal, segmentId, isSingleMeal) { return (React.createElement(AvailableServicesCountContextProvider, { value: { order: order, selectedServices: selectedServices } },
                        React.createElement(MealWrapper, __assign({ fullSize: isSingleMeal, key: meal.id, meal: meal, segmentId: segmentId, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, passengers: passengers.map(function (_a) {
                                var id = _a.id;
                                return ({
                                    id: id,
                                    name: passengerLabels[id]
                                });
                            }), isLoading: isOrderServicesSaving }, mealCallbacks)))); } }),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Toolbar, { totalPrice: mealTotalPrice, onButtonClick: onClose, onBackButtonClick: onClose }))))) }));
};
export default ServiceCardDialog;
