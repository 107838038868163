import { __assign, __read, __spreadArray } from "tslib";
import { StepType } from './types';
import { StepsEnum } from '../types';
import { seatMapHasAnySeats } from '../SeatMap/utils';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType, OrderStatus, PaymentStatusEnum } from '@websky/graphql';
import { removeServicesStepsByTypes } from './utils';
var SeatsStep = {
    url: 'seats',
    type: StepsEnum.Seats
};
var BaggageStep = {
    url: 'baggage',
    type: StepsEnum.Baggage
};
var MealStep = {
    url: 'meal',
    type: StepsEnum.Meal
};
var PassengersStep = {
    url: 'passengers',
    type: StepsEnum.Passengers
};
var InsuranceStep = {
    url: 'insurance',
    type: StepsEnum.Insurance
};
var OrderStep = {
    url: 'order',
    type: StepsEnum.Order
};
var PaymentStep = {
    url: 'payment',
    type: StepsEnum.Payment
};
export var DEFAULT_STEPS_CONFIG = [
    SeatsStep,
    BaggageStep,
    MealStep,
    PassengersStep,
    InsuranceStep,
    OrderStep,
    PaymentStep
];
export var SHOW_SERVICES_AFTER_BOOKING_STEPS = [
    PassengersStep,
    SeatsStep,
    BaggageStep,
    MealStep,
    InsuranceStep,
    OrderStep,
    PaymentStep
];
export var PRICE_FREEZE_STEPS_CONFIG = [PassengersStep, OrderStep, PaymentStep];
var isSeatsAvailable = function (order, availableServices) {
    var _a, _b;
    return ((seatMapHasAnySeats(order.flight.seatMap) &&
        availableServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Seat)) ||
        ((_b = (_a = order.flight.seatMap) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.some(function (segment) { return segment.isSeatsAvailableInCheckinOnly; })));
};
var isInsuranceAvailable = function (order) {
    var _a, _b, _c, _d;
    if (!((_b = (_a = order.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) ||
        !((_d = (_c = order.additionalServices) === null || _c === void 0 ? void 0 : _c.insurances) === null || _d === void 0 ? void 0 : _d.separateStep)) {
        return false;
    }
    return order.status !== OrderStatus.Confirmed;
};
var isMealsAvailable = function (order, availableServices) {
    var _a, _b, _c, _d, _e, _f;
    var mealServices = (_d = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (service) { return (service === null || service === void 0 ? void 0 : service.type) === OrderAdditionalServiceGdsServiceServiceType.Meal; })) !== null && _d !== void 0 ? _d : [];
    var segmentsIds = (_f = (_e = order.flight) === null || _e === void 0 ? void 0 : _e.segments) === null || _f === void 0 ? void 0 : _f.map(function (segment) { var _a; return (_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.id; });
    var hasAvailableMealServices = mealServices.some(function (meal) { var _a; return (_a = meal === null || meal === void 0 ? void 0 : meal.allowedSegments) === null || _a === void 0 ? void 0 : _a.some(function (groups) { return groups === null || groups === void 0 ? void 0 : groups.some(function (segment) { return segmentsIds.includes(segment); }); }); });
    return (availableServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Meal) && hasAvailableMealServices);
};
var isBaggageAvailable = function (availableServices, configServicesAvailable) {
    var animalAsService = !!(configServicesAvailable === null || configServicesAvailable === void 0 ? void 0 : configServicesAvailable.animal);
    return Object.values(BaggageType)
        .filter(function (type) {
        return animalAsService
            ? ![BaggageType.PetInCabin, BaggageType.PetInHold, BaggageType.PetInHandCarrier].includes(type)
            : ![BaggageType.PetInHandCarrier].includes(type);
    })
        .some(function (type) { return availableServices.hasOwnProperty(type); });
};
var isNeedToSwapPassengersAndOrder = function (order) {
    return order.isPriceFreezeProductSelected && order.paymentStatus === PaymentStatusEnum.Part;
};
var swapPassengersAndOrder = function (steps) {
    var passengersIndex = steps.findIndex(function (step) { return step.type === StepsEnum.Passengers; }), orderIndex = steps.findIndex(function (step) { return step.type === StepsEnum.Order; });
    if (passengersIndex < orderIndex && passengersIndex >= 0 && orderIndex >= 0) {
        var tmpArray = __spreadArray([], __read(steps), false);
        tmpArray[passengersIndex] = steps[orderIndex];
        tmpArray[orderIndex] = steps[passengersIndex];
        return tmpArray;
    }
    return steps;
};
var isStepAvailable = function (step, params) {
    var _a;
    var order = params.order, configStepAvailable = params.configStepAvailable, hasAvailableSeats = params.hasAvailableSeats, availableServices = params.availableServices, isPaymentRequired = params.isPaymentRequired;
    if (((_a = step === null || step === void 0 ? void 0 : step.conditions) === null || _a === void 0 ? void 0 : _a.bookedOnly) && order.status !== OrderStatus.Booked) {
        return false;
    }
    if ((configStepAvailable === null || configStepAvailable === void 0 ? void 0 : configStepAvailable[step.type]) === false) {
        return false;
    }
    switch (step.type) {
        case StepsEnum.Seats:
            return hasAvailableSeats && isSeatsAvailable(order, availableServices);
        case StepsEnum.Baggage:
            return isBaggageAvailable(availableServices, params.configServicesAvailable);
        case StepsEnum.Insurance:
            return isInsuranceAvailable(order);
        case StepsEnum.Meal:
            return isMealsAvailable(order, availableServices);
        case StepsEnum.Payment:
            return isPaymentRequired;
    }
    return true;
};
var createStepCheckingFunc = function (params) {
    return function (step) { return isStepAvailable(step, params); };
};
export var getSteps = function (params) {
    var isStepAvailable = createStepCheckingFunc(params);
    var filteredSteps = getStepsConfig(params);
    var passengersIndex = filteredSteps.findIndex(function (step) { return step.type === StepsEnum.Passengers; });
    filteredSteps = filteredSteps.map(function (step, stepIndex) { return (__assign(__assign({}, step), { hidden: !isStepAvailable(step), disabled: stepIndex <= passengersIndex
            && params.order.status === OrderStatus.Booked
            && !params.order.isPriceFreezeProductSelected })); });
    if (isNeedToSwapPassengersAndOrder(params.order)) {
        return swapPassengersAndOrder(filteredSteps);
    }
    return filteredSteps;
};
var getStepsConfig = function (params) {
    if (params.order.isPriceFreezeProductSelected &&
        (params.order.status === OrderStatus.New || !params.order.isTravellersDataFilled)) {
        return PRICE_FREEZE_STEPS_CONFIG;
    }
    if (!params.showServicesBeforeBooking) {
        return SHOW_SERVICES_AFTER_BOOKING_STEPS;
    }
    return DEFAULT_STEPS_CONFIG;
};
export var removeStepsWhenRedirectFromMetasearch = function (steps) {
    return removeServicesStepsByTypes(steps, [StepType.Seats, StepType.Meal, StepType.Baggage]);
};
