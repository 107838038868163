import { __assign, __rest } from "tslib";
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { useTheme } from '../../theme';
import PaginationItem from '@mui/material/PaginationItem';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../utils';
var siblingCountMap = {
    mobile: 0,
    tablet: 1,
    desktop: 2
};
var WxPagination = function (props) {
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    var css = useTheme('WxPagination').WxPagination;
    var _a = props.variant, variant = _a === void 0 ? 'text' : _a, pages = props.pages, onHandleChange = props.onHandleChange, children = props.children, rest = __rest(props, ["variant", "pages", "onHandleChange", "children"]);
    var handleChange = function (event, value) {
        onHandleChange(value);
    };
    var siblingCount = React.useMemo(function () {
        if (isMobile) {
            return siblingCountMap.mobile;
        }
        if (isTablet) {
            return siblingCountMap.tablet;
        }
        return siblingCountMap.desktop;
    }, [isMobile, isTablet]);
    return (React.createElement(Pagination, __assign({ classes: {
            root: css.root,
            ul: css.ul
        }, count: pages, page: props.page, onChange: handleChange, siblingCount: siblingCount, renderItem: function (item) { return (React.createElement(PaginationItem, __assign({ classes: {
                icon: css.icon,
                disabled: css.icon,
                root: css.button,
                page: css.page,
                selected: css.selected,
                previousNext: css.button
            } }, item))); } }, rest)));
};
export default WxPagination;
