import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useMemo, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { parse } from 'date-fns';
import Flight from '../../../../../Flight/Flight';
import { useTheme } from '../../../../../theme';
import Calendar from './Calendar/Calendar';
import { API_DATE_FORMAT, format, removeDotsFromDate, TABLET_MIN_WIDTH } from '../../../../../utils';
import Disclaimer from '../Disclaimer/Disclaimer';
import { Sorting } from '../../../../../Results/enums';
import Sort from './Sort/Sort';
import { getSegments, isAutoMode } from '../../../store/order/selectors';
import { sortFlights } from '../../../../../Results/components/Results/FlightsList/utils';
import { AviaPassengerType, useMinPricesQuery } from '@websky/graphql';
import ResultsError from '../../../../../ResultsError';
import { getSelectedFares } from '../../../store/newFlights/selectors';
import SelectedFlights from '../Request/SelectedFlights/SelectedFlights';
import { getFlightsToExchange, getIsAllFlights } from '../../../store/selectors';
import { OverrideComponent } from '../../../../../renderProps';
import { exchangeFlightsWithPriceToPayAdapter } from '../utils';
import { subsHalfCalendarDaysCount } from '../../../../../Results/components/Results/utils';
import { useConfig } from '../../../../../context';
import WeekCalendar from '../../../../../Results/components/Results/Calendar/WeekCalendar/WeekCalendar';
import { useHtmlDescriptionOfError } from '../hooks';
var planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 22H10L15 13.5789H20.5C21.33 13.5789 22 12.8737 22 12C22 11.1263 21.33 10.4211 20.5 10.4211H15L10 2H8L10.5 10.4211L5 10.4211L3.5 8.31579H2L3 12L2 15.6842H3.5L5 13.5789L10.5 13.5789L8 22Z", fill: "currentColor" })));
var passengerTypesToSend = [
    AviaPassengerType.ADT,
    AviaPassengerType.CLD,
    AviaPassengerType.INF,
    AviaPassengerType.INS
];
var SearchFlights = function (props) {
    var _a, _b, _c, _d;
    var theme = useTheme('Exchange').SearchFlights;
    var leg = (_a = props.resultsSearchParams) === null || _a === void 0 ? void 0 : _a.segments[props.currentLeg];
    var autoMode = useSelector(isAutoMode);
    var _e = __read(React.useState(Sorting.Price), 2), sorting = _e[0], setSorting = _e[1];
    var calendarDaysCount = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.Engine.calendarDaysCount;
    var _f = __read(React.useState(), 2), alternativePrices = _f[0], setAlternativePrices = _f[1];
    var isAllFlights = useSelector(getIsAllFlights);
    var flightsToExchange = (_c = useSelector(getFlightsToExchange)) === null || _c === void 0 ? void 0 : _c.flightsToExchange;
    var selectedFlights = useSelector(getSelectedFares);
    var segments = useSelector(getSegments);
    var formatDate = (leg === null || leg === void 0 ? void 0 : leg.date) ? removeDotsFromDate(format(leg === null || leg === void 0 ? void 0 : leg.date, 'dd MMM, EE')) : '';
    var flights = React.useMemo(function () {
        var _a;
        if (!((_a = props.legs) === null || _a === void 0 ? void 0 : _a.length)) {
            return [];
        }
        var filteredFlights = props.legs.filter(function (flight) {
            return flight.pricesForFareGroups.some(function (_a) {
                var prices = _a.prices;
                if (!prices) {
                    return false;
                }
                if (selectedFlights.length) {
                    var lastSelectedFare = selectedFlights[selectedFlights.length - 1];
                    var selectedMap_1 = {};
                    lastSelectedFare.fare.prices.forEach(function (price) { return (selectedMap_1[price.flight.id] = price.flight.id); });
                    return prices.some(function (price) { return selectedMap_1.hasOwnProperty(price.flight.id); });
                }
                else {
                    return true;
                }
            });
        });
        return exchangeFlightsWithPriceToPayAdapter(filteredFlights, props.prices, props.currentLeg);
    }, [props.legs, props.prices, props.currentLeg, selectedFlights]);
    var t = useTranslation('Exchange').t;
    var sortedFlights = React.useMemo(function () {
        return sortFlights(flights ? flights : [], sorting);
    }, [props.legs, sorting]);
    var absoluteCurrentLeg = (isAllFlights ? 0 : +flightsToExchange[0].key) + props.currentLeg;
    var minDate = useMemo(function () {
        var _a, _b, _c;
        if (isAllFlights && absoluteCurrentLeg > 0) {
            return parse((_a = props.searchParams.segments) === null || _a === void 0 ? void 0 : _a[absoluteCurrentLeg - 1].date, API_DATE_FORMAT, new Date());
        }
        else if (((_b = props.searchParams.segments) === null || _b === void 0 ? void 0 : _b.length) && absoluteCurrentLeg > 0) {
            return parse((_c = segments[absoluteCurrentLeg - 1]) === null || _c === void 0 ? void 0 : _c.segments[0].arrival.date, API_DATE_FORMAT, new Date());
        }
        return new Date(new Date().setHours(0, 0, 0, 0));
    }, [isAllFlights, segments, props.searchParams, absoluteCurrentLeg]);
    var refetchPrices = useMinPricesQuery({
        variables: {
            params: __assign(__assign({}, props.searchParams), { passengers: props.searchParams.passengers.filter(function (passenger) {
                    return passengerTypesToSend.includes(passenger.passengerType);
                }), segments: props.searchParams.segments.map(function (segment) {
                    return subsHalfCalendarDaysCount(segment, calendarDaysCount);
                }), daysCount: calendarDaysCount })
        },
        skip: true
    }).refetch;
    useEffect(function () {
        var loadPrices = function () { return __awaiter(void 0, void 0, void 0, function () {
            var alternativePrices;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, refetchPrices()];
                    case 1:
                        alternativePrices = _c.sent();
                        setAlternativePrices((_b = (_a = alternativePrices === null || alternativePrices === void 0 ? void 0 : alternativePrices.data) === null || _a === void 0 ? void 0 : _a.FlightsMinPricesInPeriod) === null || _b === void 0 ? void 0 : _b.datesWithLowestPrices);
                        return [2 /*return*/];
                }
            });
        }); };
        if (props.currentLeg === 0) {
            loadPrices();
        }
    }, [props.currentLeg, props.searchParams]);
    var htmlDescriptionOfError = useHtmlDescriptionOfError(props.error);
    return (React.createElement(React.Fragment, null, !props.loading && (React.createElement("div", null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement(React.Fragment, null,
                selectedFlights.length > 0 && (React.createElement(SelectedFlights, { prices: null, selectedFlights: selectedFlights.map(function (flight) { return (__assign(__assign({}, flight.fare.flightInfo), { segmentGroups: segments, fares: [] })); }), goToFlights: props.clearFares })),
                React.createElement("div", { className: theme.header__wrapper },
                    leg && (React.createElement("div", { className: theme.header },
                        React.createElement("div", { className: theme.leg }, props.currentLeg + 1),
                        React.createElement("span", null,
                            t('Select new flight'),
                            "\u00A0"),
                        React.createElement("span", { className: theme.header__cities },
                            leg.departure.city.name,
                            planeIcon,
                            leg.arrival.city.name))),
                    React.createElement(Calendar, { searchParams: props.searchParams, startNewSearch: props.startNewSearch, legId: props.currentLeg, minDate: minDate })),
                React.createElement("div", { className: theme.weekCalendar },
                    React.createElement(WeekCalendar, { searchParameters: props.searchParams, legId: props.currentLeg, hidePrice: false, startNewSearch: props.startNewSearch, alternativePrices: alternativePrices, minDate: minDate })),
                sortedFlights.length > 2 && (React.createElement(Sort, { onSelect: setSorting, currentSort: sorting, onFilterChange: function () { }, onlyDirect: false, onlyBusiness: false, showBusinessClass: false, showDirectFlightsSwitch: false, onBusinessClassChange: function () { } })))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", null,
                React.createElement("div", { className: theme.header }, t('Select new flight')),
                leg && (React.createElement("div", { className: theme.route },
                    React.createElement("div", null,
                        leg.departure.city.name,
                        " \u2013 ",
                        leg.arrival.city.name),
                    React.createElement("div", null, formatDate))),
                React.createElement("div", { className: theme.controls },
                    sortedFlights.length > 2 && (React.createElement(Sort, { onSelect: setSorting, currentSort: sorting, onFilterChange: function () { }, onlyDirect: false, showDirectFlightsSwitch: false, onlyBusiness: false, onBusinessClassChange: function () { } })),
                    React.createElement(Calendar, { searchParams: props.searchParams, startNewSearch: props.startNewSearch, legId: props.currentLeg, minDate: minDate })))),
        !!sortedFlights.length && (React.createElement("div", { className: theme.notices },
            !autoMode && React.createElement(Disclaimer, { type: "price" }),
            React.createElement(Disclaimer, { type: "time" }))),
        !props.loading && !sortedFlights.length && (React.createElement(ResultsError, { header: (_d = htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.header) !== null && _d !== void 0 ? _d : t("We couldn't find any flights for given dates"), body: React.createElement(OverrideComponent, { componentProps: {}, component: {
                    ExchangeErrorText: function () {
                        var _a;
                        return (React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: (_a = htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.body) !== null && _a !== void 0 ? _a : t('Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.')
                            } }));
                    }
                } }) })),
        !!sortedFlights.length &&
            sortedFlights.map(function (flight, key) {
                return (React.createElement(OverrideComponent, { componentProps: {
                        orderId: props.orderId,
                        flight: __assign({}, flight),
                        selectedFares: selectedFlights.map(function (selectedFlight) { return selectedFlight.fare; }),
                        isPreOpen: false,
                        isPreOpenAvailable: false,
                        key: "".concat(key, "_").concat(props.currentLeg),
                        onSelect: props.onFareSelect,
                        hidePrice: !flightsToExchange.length,
                        hideOnlyPrice: !flightsToExchange.length,
                        showSeatsLeftForAllFares: true,
                        isReturnFlight: props.currentLeg === 1
                    }, component: { ExchangeFlight: Flight } }));
            })))));
};
export default SearchFlights;
